import React from 'react'
import './style.scss'
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { navigate } from 'gatsby-link'

function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  export default class Questionnaire extends React.Component {
    constructor(props) {
      super(props)
      this.state = { isValidated: false }
    }
  
    handleChange = e => {
      this.setState({ [e.target.name]: e.target.value })
    }
  
    handleSubmit = e => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch(error => alert(error))
    }
  
    render() {
      return (

        <section id="questionnaireSection">
      <div className="container text-left">
    <section id="contact">
        <div className="inner">
            <section>
            <form
                name="questionnare"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="questionnare" />

                <div className="field">
                        <label className="title" htmlFor="name">Preferred Name / Nickname *</label>
                        <input type="text" name="name" id="name" 
                        onChange={this.handleChange} required />
                    </div>
                    <div className = "nameFieldWrapper">
                        <p >Name *</p>
                        <div className="field nameField">
                            <input type="text" name="firstName" id="firstName" 
                            onChange={this.handleChange} required/>
                            <label className="title nameLabel" htmlFor="firstName">First Name</label>
                        </div>
                        <div className="field nameField"> 
                            <input type="text" name="lastName" id="lastName" 
                            onChange={this.handleChange} required/>
                            <label className="title nameLabel" htmlFor="lastName">Last Name</label>
                        </div>

                    </div>
                    
                    <div className="field">
                        <label className="title" htmlFor="email">Email *</label>
                        <input type="email" name="email" id="email" 
                        onChange={this.handleChange} required/>
                    </div>

                    <div className="field">
                        <label className="title" htmlFor="age">Age</label>
                        <input type="number" name="age" id="age" 
                        onChange={this.handleChange}/>
                    </div>

                    <div className="field">
                        <label className="title" htmlFor="email"><FormattedMessage id="c_18_name" /></label>
                        <input type="text" name="name2" id="name2" 
                        onChange={this.handleChange} />
                    </div>
                    <div className="field">
                        <label className="title" htmlFor="email"><FormattedMessage id="c_18_email" /></label>
                        <input type="text" name="email2" id="email2" 
                        onChange={this.handleChange} />
                    </div>

                    <div className="field">
                        <label className="title" htmlFor="citizenship">Citizenship</label>
                        <input type="text" name="citizenship" id="citizenship" 
                        onChange={this.handleChange} />
                    </div>

                    <div className="title">Your Background</div>
                    <hr className="custom_hr" style={{marginTop:'5px'}}/>

                    <div className="field">
                        <label className="title" htmlFor="education">Education</label>
                        <p className="description">Please feel free to include work that is full-time, part-time, contract-based, freelance, internships, volunteering, etc. 
For each position, please list: Place of Work / Position / Timeframe or Year / Other Notes</p>
                        <textarea type="text" name="education" id="education" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div>

                    <div className="field">
                        <label className="title" htmlFor="workExperience">Work Experience</label>
                        <p className="description">Please feel free to include work that is full-time, part-time, contract-based, freelance, internships, volunteering, etc. 
For each position, please list: Place of Work / Position / Timeframe or Year / Other Notes
</p>
                        <textarea name="workExperience" id="workExperience" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div> 

                    <div className="title">Future Plans</div>
                    <hr className="custom_hr" style={{marginTop:'5px'}}/>

                    <div className="field">
                        <label className="title" htmlFor="fieldOfInterests">Field of Interests</label>
                        <textarea name="fieldOfInterests" id="fieldOfInterests" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div> 
                    <div className="field">
                        <label className="title" htmlFor="preferredCountries">Preferred Countries</label>
                        <textarea name="preferredCountries" id="preferredCountries" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div> 

                    <div className="field">
                        <label className="title" htmlFor="preferredUniversities">Preferred Universities</label>
                        <textarea name="preferredUniversities" id="preferredUniversities" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div> 

                    <div className="field">
                        <label className="title" htmlFor="intendedYear">Intended Academic Year / Semester</label>
                        <p className="description">eg. Fall 2020, First semester 2020, or October 2020</p>
                        <textarea name="intendedYear" id="intendedYear" 
                        onChange={this.handleChange}></textarea>
                    </div> 

                    <div className="field">
                        <label className="title" htmlFor="whyAbroad">Why do you want to study abroad?</label>
                        <textarea name="whyAbroad" id="whyAbroad" rows="6" 
                        onChange={this.handleChange}></textarea>
                    </div> 

                    <div className="title">CIGAM Support</div>
                    <hr className="custom_hr" style={{marginTop:'5px'}}/>

                    <div id="contactOption"> 
                            
                            <label className="title" htmlFor="servicesOption">Please indicate which of these services you may be interested in</label>
                            
                            <div className="option">
                                <label>
                                <input type="checkbox" name="servicesOption1" value="resumeAssistanse" 
                                    onChange={this.handleChange}>
                                </input>
                                Assistance in writing/revision of resume and portfolio
                                </label>
                            </div>
                            
                            <div className="option">
                                <input type="checkbox" name="servicesOption2" value="writingTutoring" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Writing tutoring (essays, CVs, SOPs, Scholarship, etc.)
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption3" value="interviewPreparation" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Preparation for the interview
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption4" value="examsPreparation" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Preparation for exams (English / Maths / Other subjects)
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption5" value="languageLearning" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Language learning for business studies (French, Russian, etc.)
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption6" value="applicationRevision" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Professional revision of pre-filled application
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption7" value="budgeting" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Financial planning / budgeting
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption8" value="accomodation" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Accommodation
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="servicesOption9" value="lifestyle" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Lifestyle advice
                                </label>
                            </div>

                        </div>

                        <div className="field">
                            <label className="title" htmlFor="budgetLimitations">Budget Limitations</label>
                            <p className="description">For studies including consulting service. Please indicate currency (eg. USD / GBP / THB / RUB)</p>
                            <textarea name="budgetLimitations" id="budgetLimitations" 
                            onChange={this.handleChange}></textarea>
                        </div> 

                        <hr className="custom_hr" style={{marginTop:'5%'}}/>

                        <div id="contactOption"> 
                            
                            <label className="title" htmlFor="channels">How did you find out about CIGAM?</label>
                            
                            <div className="option">
                                <label>
                                <input type="checkbox" name="channelOption1" value="friends" 
                                    onChange={this.handleChange}>
                                </input>
                                Friends
                                </label>
                            </div>
                            
                            <div className="option">
                                <input type="checkbox" name="channelOption2" value="internet" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Internet
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="channelOption3" value="socialMedia" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Social Media
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="channelOption4" value="careerFair" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Career Fair
                                </label>
                            </div>

                            <div className="option">
                                <input type="checkbox" name="channelOption5" value="others" 
                                onChange={this.handleChange}>
                                </input>
                                <label> Others
                                </label>
                            </div>

                        </div>

                        <div className="field">
                        <label className="title" htmlFor="other">Other comments</label>
                        <p className="description">Anything else you would like us to know about you?</p>
                        <textarea name="other" id="other" rows="6" 
                        onChange={this.handleChange}></textarea>
                        </div> 
                       

                <div id="contactButton" className="d-flex flex-row justify-content-center">
                  <button className="mt-3 mb-5 warning warningCustom is-link" type="submit">
                  <FormattedMessage id="button_submit" />   
                  </button>
                </div>

              </form>
            </section>
 
        </div>
                    
    </section>

    </div>
    </section>

        )
    }
  }
