import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'

import Questionnaire from 'components/Questionnaire'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"


const QuestionnairePage = ({ intl }) => {
  

  return (

    <Layout> 
      <section id="contactSection">
      <div className="container pt-5 pb-5 text-center">
      <h1 id="questionnaireHeading">Prospective Student Questionnaire</h1>
      <p id="questionnaireP">Please help us get to know you more, so our consultant team can provide specialized advice for you and your specific situation. 
      We look forward to learning more about you and working with you on your journey. </p>
        </div>
        </section>

        <Questionnaire />

    </Layout>
  )
}

export default injectIntl(QuestionnairePage)



 